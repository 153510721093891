import React, { useEffect, useState } from 'react';
import {
  MessageList,
  MessageInput,
  Window,
  useChannelActionContext,
  Avatar,
  useChannelStateContext,
  useChatContext,
} from 'stream-chat-react';
import starIcon from '../assets/star-icon.png';
import bellIcon from '../assets/bell-icon.png';
import reservationIcon from '../assets/reservation-icon.png';

import { ChannelInfo } from '../assets';

const customersUI = require('./customerUI.json');
const customer = customersUI.customers[customersUI.customerChoice];
const restaurantName = customer.restaurantName;

const ChannelInner = ({ setIsEditing }) => {
  const [giphyState, setGiphyState] = useState(false);
  const { sendMessage } = useChannelActionContext();

  const overrideSubmitHandler = (message) => {
    let updatedMessage = {
      attachments: message.attachments,
      mentioned_users: message.mentioned_users,
      parent_id: message.parent?.id,
      parent: message.parent,
      text: message.text,
    };

    if (giphyState) {
      updatedMessage = { ...updatedMessage, text: `/giphy ${message.text}` };
    }

    if (sendMessage) {
      sendMessage(updatedMessage);
      setGiphyState(false);
    }
  };

  const customActions = {
    'Copy text': (message) => {
      navigator.clipboard.writeText(message.text || '')
    },
  };

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <Window>
        <TeamChannelHeader setIsEditing={setIsEditing} />
        <MessageList messageActions={['edit', 'delete']} />
        <MessageInput overrideSubmitHandler={overrideSubmitHandler} grow={true} />
      </Window>
      {/*<Thread />*/}
    </div>
  );
};

const TeamChannelHeader = ({ setIsEditing }) => {
  const { channel, watcher_count } = useChannelStateContext();
  const { client } = useChatContext();
  const [isStarred, setIsStarred] = useState(channel?.data?.starred || false);
  const [isAttention, setIsAttention] = useState(channel?.data?.attention || false);
  const [bellTooltip, setBellTooltip] = useState(false);
  const [starTooltip, setStarTooltip] = useState(false);
  const [showIframe, setShowIframe] = useState(false); // New state for showing iframe

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phoneNumber;
  };

  const formatDate = (isoString, includeTime = true) => {
    const date = new Date(isoString);
    const options = includeTime
      ? {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        }
      : {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  const calculateDaysAsCustomer = (createdDate) => {
    const created = new Date(createdDate);
    const today = new Date();
    const differenceInTime = today.getTime() - created.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  };

  const MessagingHeader = () => {
    const members = Object.values(channel.state.members).filter(
      ({ user }) => user.id !== client.userID && user.role === 'guest'
    );

    if (channel.type === 'messaging') {
      return (
        <div className='team-channel-header__name-wrapper'>
          {members.map(({ user }, i) => (
            <div key={i} className='team-channel-header__name-multi'>
              <Avatar image={user.image} name={user.fullName || user.id} size={32} />
              <div className='user-info'>
                <p className='team-channel-header__name user'>{user.fullName || user.id}</p>
                <div className='info-display-box'>
                  <p className='team-channel-header__name phone'>
                    <span className='label'>Phone Number: </span>
                    <span className='data'>{formatPhoneNumber(user.phoneNumber)}</span>
                  </p>
                  <p className='team-channel-header__name created'>
                    <span className='label'>Conversation Created: </span>
                    <span className='data'>{formatDate(channel.data.created_at, false)}</span>
                  </p>
                  <p className='team-channel-header__name last-message-at'>
                    <span className='label'>Last Message: </span>
                    <span className='data'>{formatDate(channel.data.last_message_at)}</span>
                  </p>
                  <p className='team-channel-header__name days-as-customer'>
                    <span className='label'>Days as a Superhero {restaurantName} Customer: </span>
                    <span className='data'>{calculateDaysAsCustomer(channel.data.created_at)} days</span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className='team-channel-header__channel-wrapper'>
        <p className='team-channel-header__name'># {channel.data.name}</p>
        <span style={{ display: 'flex' }} onClick={() => setIsEditing(true)}>
          <ChannelInfo />
        </span>
      </div>
    );
  };

  const getWatcherText = (watchers) => {
    if (!watchers) return 'No users online';
    if (watchers === 1) return '1 user online';
    return `${watchers} users online`;
  };

  useEffect(() => {
    setIsStarred(channel?.data?.starred || false);
    setIsAttention(channel?.data?.attention || false);
  }, [channel]);

  const handleStarChannel = () => {
    try {
      channel.updatePartial({
        set: {
          starred: !isStarred,
        },
      });
      setIsStarred((prevIsStarred) => !prevIsStarred);
    } catch (error) {
      console.error('Error toggling star for channel:', error);
    }
  };

  const handleAttentionChannel = () => {
    try {
      channel.updatePartial({
        set: {
          attention: !isAttention,
        },
      });
      setIsAttention((prevIsAttention) => !prevIsAttention);
    } catch (error) {
      console.error('Error toggling attention for channel:', error);
    }
  };

  const handleReservationIconClick = () => {
    setShowIframe(!showIframe);
  };

  const starBackgroundClass = isStarred ? 'star-background-active' : 'star-background';
  const bellBackgroundClass = isAttention ? 'bell-background-active' : 'bell-background';

  return (
    <div className='team-channel-header__container'>
      <MessagingHeader />
      <div className='team-channel-header__right'>
        <div
          className={starBackgroundClass}
          onClick={handleStarChannel}
          onMouseEnter={() => setStarTooltip(true)}
          onMouseLeave={() => setStarTooltip(false)}>
          <img src={starIcon} alt='Star Channel' className='star-icon' />
          {starTooltip && (
            <span className='tooltip'>
              {isStarred ? 'Remove from' : 'Include in'} <strong>Starred Conversations</strong>
            </span>
          )}
        </div>
        <div
          className={bellBackgroundClass}
          onClick={handleAttentionChannel}
          onMouseEnter={() => setBellTooltip(true)}
          onMouseLeave={() => setBellTooltip(false)}>
          <img src={bellIcon} alt='Attention Needed' className='bell-icon' />
          {bellTooltip && (
            <span className='tooltip'>
              Move to <strong>{isAttention ? 'Replied' : 'Attention Needed'}</strong>
            </span>
          )}
        </div>
        {showIframe && (
          <iframe
            src='https://example.com'
            title='Reservation'
            className='reservation-iframe'
          />
        )}
        <p className='team-channel-header__right-text'>{getWatcherText(watcher_count)}</p>
      </div>
    </div>
  );
};

export default ChannelInner;
